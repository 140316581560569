import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Button, Select, Tabs, Table,notification } from 'antd';
import { AuthTokenType, lead, location, product } from '../utils/types';
import { useGetLead, useGetLeadestimates, useGetLocation, useGetProduct } from '../utils/hooks';
import DrawingBoard from 'react-drawing-board';
import SignatureCanvas from 'react-signature-canvas';
import AddIcon from '@mui/icons-material/Add';
import { getAuthToken } from '../utils/functions';
import axios, { AxiosResponse } from 'axios';
import { estimatesUrl } from '../utils/network';
import ReactDatePicker from 'react-datepicker';
import DrawingCanvas from '../components/DrawingCanvas';

const { TabPane } = Tabs;
const { Option } = Select;

// Define a new type for estimated materials
interface EstimatedMaterial {
  id: number;
  name: string;
  quantity: number;
}

const EstimateForm = () => {
  const [fetching, setFetching] = useState(true);
  const [toLead,setToLead]=useState<lead[]>([])
  const [toLeadselected,setToLeadSelected]=useState("")
  useGetLeadestimates(setToLead,setFetching)
  const drawingBoardRef = useRef<any>(null);
  const signatureCanvasRef = useRef<any>(null);
  const [fromlocation, setFromLocation] = useState<location[]>([]);
  const [fromlocationselected, setFromLocationSelected] = useState("");
  const [product, setProduct] = useState<product[]>([]);
  useGetProduct(setProduct, setFetching);
  const [estimatedMaterials, setEstimatedMaterials] = useState<EstimatedMaterial[]>([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [edd, Setedd] = useState<Date | null>(new Date());


  useEffect(() => {
    const tabs = document.querySelectorAll('.ant-tabs-tab');

    tabs.forEach(tab => {
      tab.addEventListener('pointerdown', (event) => {
        (event.target as HTMLElement).click();
      });
    });

    return () => {
      tabs.forEach(tab => {
        tab.removeEventListener('pointerdown', () => {});
      });
    };
  }, []);

  const onFinish = async (values: any) => {
    try {
      await submitsto(values, form);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleProductNameChange = (value: number, index: number) => {
    // Find the selected product
    const selectedProduct = product.find((prod) => prod.id === value);
    if (selectedProduct) {
      const newMaterials = [...estimatedMaterials];
      // Update the id and name of the material at the specified index
      newMaterials[index] = {
        id: selectedProduct.id,
        name: selectedProduct.name,
        quantity: newMaterials[index].quantity // Maintain the existing quantity
      };
      setEstimatedMaterials(newMaterials);
    }
  };
  
  const handleAddProduct = () => {
    setEstimatedMaterials([...estimatedMaterials, { id: 0, name: "", quantity: 0 }]);
  };

  const handleQuantityChange = (value: number, index: number) => {
    const newMaterials = [...estimatedMaterials];
    newMaterials[index].quantity = value;
    setEstimatedMaterials(newMaterials);
  };

  const handleDeleteMaterial = (index: number) => {
    const newMaterials = [...estimatedMaterials];
    newMaterials.splice(index, 1);
    setEstimatedMaterials(newMaterials);
  };

  const productcolumns=[
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: EstimatedMaterial, index: number) => (
        <Select
          
          onChange={(value) => handleProductNameChange(value, index)}
          style={{ minWidth: "200px" }}
        >
          {product.map((product) => (
            <Option key={product.id} value={product.id}>
              {product.productdropdown}
            </Option>
          ))}
        </Select>
      )
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text: number, record: EstimatedMaterial, index: number) => (
        <Input
          value={text}
          onChange={(e) => handleQuantityChange(+e.target.value, index)}
          type="number"
          min={0}
        />
      )
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any, index: number) => (
        <Button type="link" onClick={() => handleDeleteMaterial(index)}>
          Delete
        </Button>
      )
    }
  ]
  const [drawingBoardImage, setDrawingBoardImage] = useState("");
  const handleSaveImage = (image: { canvas: HTMLCanvasElement, dataUrl: string }) => {
    // Convert the image data to base64 format

    const base64Image = image.dataUrl.split(',')[1];
    // Save the base64-encoded image data to state
    setDrawingBoardImage(base64Image);
  };
  



  const submitsto = async (values: any, form: any) => {
    const signatureDataURL = signatureCanvasRef.current.toDataURL();
    
    const datatosend = {
      from_location_id: fromlocationselected,
      lead_id : toLeadselected,
      expected_delivery_date: values.date,
      status: "Open",
      estimate_items_data: estimatedMaterials.map((item) => ({
        item_id: item.id,
        quantity: item.quantity,
      })),
      signature: signatureDataURL, // Add customer signature data URL
      totalprice: values.totalprice,
      deposit: values.deposit,
      estimate_image: drawingBoardImage,
      mode:values.mode, 
      specialinstructions: values.specialinstructions,
      completiondate: edd,
      preferredinstallday: values.preferredinstallday,
      preferredstarttime: values.preferredstarttime,
      source: values.source
    };
    setLoading(true);
    try {
      const headers = getAuthToken() as AuthTokenType;
      const response: AxiosResponse = await axios.post(
        estimatesUrl,
        datatosend,
        headers
      );
  
      notification.success({
        message: "Operation Success",
        description: "Estimate Created Successfully",
      });
      form.resetFields();
  
    } catch (e) {
      notification.error({
        message: "Operation Error",
      });
      form.resetFields();
    } finally {
      setLoading(false);
    }
  };

  const clearCanvas = () => {
    if (signatureCanvasRef.current) {
      signatureCanvasRef.current.clear(); // Call the clear method
    }
  };  
  useGetLocation(setFromLocation, setFetching);

  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const handleSaveImage1 = (dataUrl: string) => {
    const base64Image = dataUrl.split(',')[1]; // Extract the base64 part
    setDrawingBoardImage(base64Image); // Save the base64-encoded image data
  };


  return (
    <div className="card">
      <h2 className="headContent">Create Estimate</h2>
        <div>
    <Form
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Tabs defaultActiveKey="1">
      <TabPane tab="Primary Details" key="1">
              <div className="tabContent">
                <div className="leftPane">
          <Form.Item label="Serving Location" name="from_location_id">
                    <Select
                      defaultValue=""
                      onChange={(value) => setFromLocationSelected(value)}
                    >
                      <Option value="">Serving Location</Option>
                      {fromlocation.map((item, index) => (
                        <Option value={item.id} key={index}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Lead Name" name="to_Lead_id">
                    <Select defaultValue="" onChange={(value) => setToLeadSelected(value)}>
                      <Option value="">Lead</Option>
                      {toLead.map((item, index) => (
                        <Option value={item.id} key={index}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Special Instructions" name="specialinstructions">
                    <Input.TextArea />
                  </Form.Item>
                  <Form.Item label='Completion Date' name="completiondate">
            <ReactDatePicker selected={edd} onChange={(date) => Setedd(date)}  />
          </Form.Item>                  
                  <Form.Item label="Preferred Install Day" name="preferredinstallday">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Preferred Start Time" name="preferredstarttime">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Source" name="source">
                    <Input />
                  </Form.Item>
                </div>
                <div className="rightPane">
                  <Table
                    className="custom-table"
                    size="small"
                    dataSource={estimatedMaterials}
                    columns={productcolumns}
                  />
                  <Button type="primary" onClick={handleAddProduct}><AddIcon/></Button>
                </div>
              </div>
            </TabPane>

        <TabPane  tab="Diagram" key="2">

        <Form.Item className="diagram" label="" name="estimate_image">
        <DrawingCanvas  onSave={handleSaveImage1}  />
        </Form.Item>
        </TabPane>


      <TabPane tab="Payment Details" key="4">
      <Form.Item label="Total Price" name="totalprice">
        <Input />
      </Form.Item>
      <Form.Item label="Mode of Payment" name="mode">
  <Select>
    <Select.Option value="cash">Cash</Select.Option>
    <Select.Option value="check">Check</Select.Option>
    <Select.Option value="credit_card">Credit Card</Select.Option>
    <Select.Option value="bank_transfer">Bank Transfer</Select.Option>
    <Select.Option value="paypal">PayPal</Select.Option>
    <Select.Option value="zelle">Zelle</Select.Option>
    <Select.Option value="venmo">Venmo</Select.Option>
  </Select>
</Form.Item>

      <Form.Item label="Customer Signature"
          name="customer_signature">
            <div style={{ border: "1px solid #ccc", padding: 10 }}>
              <SignatureCanvas
                ref={signatureCanvasRef}
                penColor='black'
                canvasProps={{ width: "500%", height: 100,  }}
              />
            </div>
          </Form.Item>

      <Form.Item label="">
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
      </TabPane>
      
      </Tabs>
    </Form>
    </div>
</div>


  );
};

export default EstimateForm;