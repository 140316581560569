import React, { FC, useEffect, useState } from "react";
import { Drawer, Input, Button, List, notification } from "antd";
import axios from "axios";
import { AuthTokenType, lead } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import { getconversationsUrl, sendconversationsUrl } from "../utils/network";

interface SMSInterfaceProps {
  lead: lead | null; // Allow lead to be null
  visible: boolean;
  onClose: () => void;
}

const SMSInterface: FC<SMSInterfaceProps> = ({ lead, visible, onClose }) => {
  const [messages, setMessages] = useState<{ sender: string; text: string; timestamp: string }[]>([]);
  const [inputMessage, setInputMessage] = useState("");

  useEffect(() => {
    if (visible && lead) {
      fetchMessages();
    }
  }, [visible, lead]);

  const fetchMessages = async () => {
    if (lead) {
      try {
        const headers = getAuthToken() as AuthTokenType;
        const response = await axios.post(getconversationsUrl, {
          lead_id: lead.id, // Send the lead ID
        }, headers);
        
        // Extracting messages from the response
        const fetchedMessages = response.data.data.map((conv: any) => ({
          sender: conv.flag === 'system' ? 'System' : lead.name,
          text: conv.message,
          timestamp: conv.created_at,
        }));

        // Sort messages by timestamp
        fetchedMessages.sort((a:any, b:any) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

        setMessages(fetchedMessages);
      } catch (error) {
        notification.error({
          message: "No Conversations Found",
        });
      }
    }
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() && lead) {
      try {
        const headers = getAuthToken() as AuthTokenType;
        await axios.post(sendconversationsUrl, {
          lead_id: lead.id, // Send the lead ID
          message: inputMessage, // Send the message
          flag: 'lead', // Set the flag for the message being sent
        }, headers);

        // Add user's message to the chat
        const newMessage = { sender: "You", text: inputMessage, timestamp: new Date().toISOString() };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setInputMessage("");

        // Optionally, fetch updated messages from the API
        fetchMessages();
      } catch (error) {
        notification.error({
          message: "Error sending message",
        });
      }
    }
  };

  return (
    <Drawer
      title={`SMS with ${lead ? lead.name : "Lead"}`}
      placement="right"
      closable={true}
      onClose={onClose}
      visible={visible}
      width={400}
    >
      <List
        style={{ maxHeight: 300, overflowY: "auto" }}
        dataSource={messages}
        renderItem={(msg) => (
          <List.Item style={{ justifyContent: msg.sender === "You" || msg.sender === lead?.name ? "flex-end" : "flex-start" }}>
            <div style={{ textAlign: msg.sender === "You" || msg.sender === lead?.name ? "right" : "left" }}>
              <strong>{msg.sender}:</strong> {msg.text}
            </div>
          </List.Item>
        )}
      />
      <Input
        value={inputMessage}
        onChange={(e) => setInputMessage(e.target.value)}
        onPressEnter={handleSendMessage}
        placeholder="Type a message..."
        style={{ marginTop: 10 }}
      />
      <Button type="primary" onClick={handleSendMessage} style={{ marginTop: 10 }}>
        Send
      </Button>
    </Drawer>
  );
};

export default SMSInterface;